import React from 'react';
import { ThemeProvider } from './src/components/ContextProviders/ThemeProvider';
// import { Redirect } from './src/components/LanguageHelpers/Redirect';
import './src/assets/scss/reset.global.scss'
import './src/assets/scss/main.global.scss'

export const wrapPageElement = ({ element }) => (
  <ThemeProvider>
    {/* <Redirect /> */}
    {element}
  </ThemeProvider>
);
